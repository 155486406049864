import { Link } from "react-scroll";
import React from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import CodeIcon from '@mui/icons-material/Code';

function Footer() {
  return (
    <footer className="footer--container">
      <div className="footer--link--container">
        <div className="footer--items">
          <ul>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="heroSection"
                className="text-md cursor-pointer"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="MyEdEx"
                className="text-md cursor-pointer"
              >
                Education & Experience
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="MyProjects"
                className="text-md cursor-pointer"
              >
                Projects
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="MySkills"
                className="text-md cursor-pointer"
              >
                Skills
              </Link>
            </li>
            <li className="text-center">
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="MyAchEx"
                className="text-md cursor-pointer"
              >
                Achievements & Extracurriculars
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="MyCert"
                className="text-md cursor-pointer"
              >
                Certifications
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer--social--icon">
          <ul>
            <li>
              <a href="https://www.linkedin.com/in/sarthakgoel97/" target="_blank" rel="noopener noreferrer">
                <LinkedInIcon
                  fontSize="large"
                  color="primary"
                />
              </a>
            </li>
            <li>
              <a href="https://github.com/sarthakgoel1997" target="_blank" rel="noopener noreferrer">
                <GitHubIcon
                  fontSize="large"
                  style={{ color: 'black' }}
                />
              </a>
            </li>
            <li>
              <a href="mailto:sarthakgoel_97@hotmail.com" target="_blank" rel="noopener noreferrer">
                <EmailIcon
                  fontSize="large"
                  color="primary"
                />
              </a>
            </li>
            <li>
              <a href="https://leetcode.com/u/sarthak97/" target="_blank" rel="noopener noreferrer">
                <CodeIcon
                  fontSize="large"
                  style={{ color: 'black' }}
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
