let timelineElements = [
    {
      id: 1,
      title: "Cloud Engineer Intern | S&P Global",
      location: "New York, United States",
      description:
        "- Created strategic upskilling content, including bootcamp around FinOps best practices and Terraform certification guide<br><br>- Implemented Google Analytics and Power BI reporting solutions to help track user engagement and bootcamp success<br><br>- Demonstrated understanding of core cloud technologies and processes by contributing to 8 AWS terraform modules across 10 weeks",
      date: "May 2024 - August 2024",
      icon: "work",
    },
    {
      id: 2,
      title: "New York University",
      location: "New York, United States",
      description:
        "Master of Science - MS, Computer Science",
      date: "September 2023 - Present",
      icon: "school",
    },
    {
      id: 3,
      title: "Software Development Engineer II | Zomato",
      location: "Gurugram, India",
      description:
        "- Boosted 15% monthly GMV by revamping Credit System for Hyperpure customers: automated SOAs, dedicated credit ledger, credit offering on consumer app, dynamic credit limit<br><br>- Increased 50% absolute margins for egg product by benchmarking its sourcing and consumer platform prices to NECC<br><br>- Reduced 90% manual effort and time spent in inventory planning by building Auto Replenishment System which predicts optimal product quantities to be ordered at any given point in time",
      date: "July 2023 - August 2023",
      icon: "work",
    },
    {
      id: 4,
      title: "Software Development Engineer I | Zomato",
      location: "Gurugram, India",
      description:
      "- Achieved 90% reduction in customer issue resolutions TAT from 40+ to avg. 3 hours by collaborating on cross-functional Ticket Management System<br><br>- Helped 15000 non-admin users (delivery agents, warehouse helpers) achieve proficiency in roles by developing Training Module System<br><br>- Optimized various API calls, the most prominent one being reducing response time from 5-10s to less than 300ms, through refactoring and segment analysis",
      date: "May 2021 - June 2023",
      icon: "work",
    },
    {
      id: 5,
      title: "Netaji Subhas Institute of Technology",
      location: "New Delhi, India",
      description:
        "Bachelor of Engineering - BE, Information Technology",
      date: "August 2017 - June 2021",
      icon: "school",
    },
    {
      id: 6,
      title: "Technical Content Intern | PrepBytes",
      location: "New Delhi, India",
      description:
        "- Instructed undergraduate students for 25+ online C++/Python data structures and algorithms live sessions<br><br>- Designed, audited, reviewed, and tested 350+ coding problems<br><br>- Authored over 10 technical articles on coding questions to enhance students' comprehension",
      date: "February 2021 - April 2021",
      icon: "work",
    },
    {
      id: 7,
      title: "Teaching Assistant | Coding Blocks",
      location: "New Delhi, India",
      description:
        "- Supported 100+ students across 3 batches of C/C++ foundation and data structures courses<br><br>- Solved 800+ doubts on questions asked in technical interviews",
      date: "February 2020 - April 2021",
      icon: "work",
    },
  ];
  
  export default timelineElements;
  