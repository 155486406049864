import data from "../../data/index.json";

export default function MyCert() {
  return (
    <section className="skills--section" id="MyCert">
      <div className="portfolio--container">
        <h2 className="skills--section--heading">Certifications</h2>
      </div>
      <div className="skills--section--container">
        <a href="https://www.credly.com/badges/0f5194c7-0208-46b5-9a3e-fb42906f7dd3/public_url" target="_blank" rel="noopener noreferrer">
          <img src="./img/aws-certified-cloud-practitioner.png" alt="AWS" style={{ width: '300px', height: '300px' }} className="profile-btn" />
        </a>

        <a href="https://www.credly.com/badges/a04894c0-b1dc-4367-96bb-3d6f5a6b6996/public_url" target="_blank" rel="noopener noreferrer">
          <img src="./img/hashicorp-certified-terraform-associate-003.png" alt="Resume" style={{ width: '300px', height: '300px' }} className="profile-btn" />
        </a>
      </div>
    </section>
  );
}
