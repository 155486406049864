import data from "../../data/index.json";

export default function MyAchEx() {
  return (
    <section className="skills--section" id="MyAchEx">
      <div className="portfolio--container">
        <h2 className="skills--section--heading text-center">Achievements & Extracurriculars</h2>
      </div>
      <div className="skills--section--container custom-skills-height">

        {data?.achex?.map((item, index) => (
          <div key={index} className="skills--section--card min-height-670">
            <div className="skills--section--img">
              <img src={item.src} alt="Product Chain" style={{ width: '60px', height: '60px' }} />
            </div>
            <div className="skills--section--card--content">
              <h3 className="skills--section--title">{item.title}</h3>

              {item?.content?.map((c, index) => (<p id="description" className={`skills--section--description ${c.link ? 'cursor-pointer' : ''}`} dangerouslySetInnerHTML={{ __html: c.description }} onClick={() => c.link ? window.open(c.link, '_blank') : ''}/>))}

            </div>
          </div>
        ))}

      </div>
    </section>
  );
}
